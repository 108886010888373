/**
 * 
 * @param {number} numberOfImages 
 * @param {number} numberOfColumnsDesktop 
 * @param {number} numberOfColumnsMobile 
 * @returns 
 */
const imageOptionsGenerator = (numberOfImages, numberOfColumnsDesktop, numberOfColumnsMobile) => {
  return [...Array(numberOfImages).keys()].reduce((accumulator, current, index) => {
    const currentIndexPlus1 = index + 1;

    const containerDesktopWidth = 700;
    const containerMobileWidth = 596;
    const imageDesktopWidth = Math.floor(containerDesktopWidth / numberOfColumnsDesktop);
    const imageMobileWidth = Math.floor(containerMobileWidth / numberOfColumnsMobile);

    accumulator[`imgSrcDesktop${currentIndexPlus1}`] = {
      label: `Image ${currentIndexPlus1} Desktop Src`,
      value: `https://via.placeholder.com/${imageDesktopWidth}`,
    };

    accumulator[`imgSrcMobile${currentIndexPlus1}`] = {
      label: `Image ${currentIndexPlus1} Mobile Src`,
      value: `https://via.placeholder.com/${imageMobileWidth}`,
    };

    accumulator[`hrefImage${currentIndexPlus1}`] = {
      label: `Image ${currentIndexPlus1} Link`,
      value: "https://direct.asda.com/george/clothing/10,default,sc.html",
    };

    accumulator[`altTag${currentIndexPlus1}`] = {
      label: `Image ${currentIndexPlus1} Alt Tag`,
      value: "Alt Tag",
    };

    return accumulator;
  }, {});
};

export default imageOptionsGenerator;
