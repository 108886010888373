<template>
  <div>
    <Modal ref="modal" heading="Edit Module:" class="modal__edit-module">
      <div class="edit-module__label" v-if="tierIndexPlus1">
        <span class="edit-module__label__tier-index">{{tierIndexPlus1}}</span>
        {{moduleData.label}}
      </div>
      <form class="edit-module__form" v-if="editableAttributesKeys">
        <div
          v-for="attribute in editableAttributesKeys"
          v-bind:key="attribute"
          class="edit-module__form-field"
        >
          <label>{{
            moduleData.editableAttributes[attribute].label
          }}</label>
          <textarea 
            type="text"
            v-model="moduleData.editableAttributes[attribute].value"
          />
        </div>
      </form>
      <div class="edit-module__btn-container">
        <button
          v-on:click="updateModule"
          class="edit-module__btn">
          Apply Changes
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { bus } from "../main";
import Modal from "./Modal.vue";

export default {
  name: "EditModule",
  components: {
    Modal,
  },
  data() {
    return {
      showing: false,
      moduleData: null
    };
  },
  computed: {
    tierIndexPlus1() {
      if (!this.moduleData || !(this.moduleData.tierIndex + 1)) {
        return;
      }
      return this.moduleData.tierIndex + 1;
    },
    editableAttributesKeys() {
      if (!this.moduleData || !this.moduleData.editableAttributes) {
        return null;
      }
      // remove moduleName from edit module attribute fields
      const attributesExclModuleName = Object.keys(this.moduleData.editableAttributes).filter(attr=>attr != 'moduleName');
      
      return attributesExclModuleName;
    },
  },
  methods: {
    updateModule() {
      bus.$emit("updateModule", this.moduleData);
      this.$refs.modal.hide();
    },
  },
  mounted() {
    bus.$on("editingModule", (payload) => {
      this.moduleData = payload;
      this.$refs.modal.show();
    });
  },
};
</script>

<style scoped>
.edit-module__label {
  height: var(--modal-top-bar-height);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  font-weight: bold;
  font-size: 20px;
  color: hsl(46, 50%, 60%);
}

.edit-module__label__tier-index {
  width: 26px;
  height: 26px;
  margin-right: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: hsl(46, 50%, 60%);
  color: #fff;
  font-size: 20px;
}

.edit-module__form {
  width: 100%;
  padding-right: 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.edit-module__form-field {
  flex-basis: clamp(calc(50% - 16px), 400px, 100%);
  flex-grow: 1;
}

.edit-module__form label {
  min-height: 24px;
  display: block;
  margin-bottom: 2px;
  font-weight: bold;
}

.edit-module__form textarea {
  width: 100%;
  padding: 8px 12px;
  background-color: hsl(220, 13%, 91%);
  color: hsl(217, 19%, 27%);
  border: 2px solid white;
  border-radius: 8px;
  line-height: 1.2;
}
.edit-module__form textarea:focus {
  border: 2px solid hsl(46, 50%, 60%);
  outline: 2px solid white;
  outline-offset: 2px;
}

.edit-module__btn-container {
  width: 100%;
  height: var(--modal-bottom-bar-height);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.edit-module__btn {
  margin: 0 16px 0 0;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: hsl(162, 72%, 37%);
  color: #fff;
}

.edit-module__btn:hover {
  background-color: hsl(162, 72%, 43%);
}

</style>