<template>
    <div id="app">
        <div class="app-container">
            <Editor />
            <Preview />
        </div>
        <ModuleSelector />
        <TaggingTool />
        <EditModule />
        <CopyHtml />
        <SaveModal />
        <LoadModal />
    </div>
</template>

<script>
import Editor from "./components/Editor.vue";
import Preview from "./components/Preview.vue";
import ModuleSelector from "./components/ModuleSelector.vue";
import TaggingTool from "./components/TaggingTool.vue";
import EditModule from "./components/EditModule.vue";
import CopyHtml from "./components/CopyHtml.vue";
import SaveModal from "./components/SaveModal.vue";
import LoadModal from "./components/LoadModal.vue";

export default {
    components: {
        Editor,
        Preview,
        ModuleSelector,
        TaggingTool,
        EditModule,
        CopyHtml,
        SaveModal,
        LoadModal,
    },
};
</script>
<style scoped>
#app {
    font-family: 'Lato', arial, sans-serif;
}

.app-container {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}
.email-editor {
    height: 100vh;
    flex: 0 1 100%;
}
.email-preview {
    height: 100vh;
    flex: 0 0 860px;
}
</style>