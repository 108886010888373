<template>
  <div>
    <Modal ref="modal" heading="HTML:" class="modal__email-html">
      <div class="email-html__code-preview">
        <pre>
          <code>{{ html }}</code>
        </pre>
      </div>
      <div class="email-html__btn-container">
          <button v-on:click="copyHTML" class="email-html__btn">
            Copy HTML
          </button>
          <button v-on:click="downloadHtml" class="email-html__btn">
            Download HTML
          </button>
      </div>
      
    </Modal>
  </div>
</template>

<script>
import { bus } from "../main";
import Modal from "./Modal.vue";

export default {
  name: "CopyHtml",
  components: {
    Modal,
  },
  data() {
    return {
      html: "",
      showing: false,
    };
  },
  methods: {
    escapeHTML(string) {
      return string;
    },
    copyHTML() {
      navigator.clipboard.writeText(this.html);
      this.$alert('HTML copied to clipboard');
    },
    downloadHtml() {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.html));
      element.setAttribute('download', 'email.html');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  },
  mounted() {
    bus.$on("openHtml", (payload) => {
      this.html = this.escapeHTML(payload);
      this.$refs.modal.show();
    });
  },
};
</script>

<style scoped>

.email-html__code-preview {
  background-color: hsl(217, 19%, 20%);
  color: #fff;
}
.email-html__code-preview pre {
  white-space: pre-wrap;
}

.email-html__code-preview code {
  word-break: break-word;
}

.email-html__btn-container {
  width: 100%;
  height: var(--modal-top-bar-height);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.email-html__btn {
  margin: 0 16px 0 0;
  padding: 8px 16px;
  background-color: hsl(46, 50%, 48%);
  border-radius: 8px;
  color: #fff;
}
.email-html__btn:hover {
  background-color: hsl(46, 50%, 54%);
}
</style>
