<template>
    <div>
        <Modal ref="modal" heading="Tagging:">
            <form class="tagging-tool__form">
                <div class="tagging-tool__form-field tagging-tool__form-field--radio">
                    <p>Platform</p>

                    <input type="radio" id="salesforce" value="Salesforce" v-model="selectedPlatform" />
                    <label for="salesforce">Salesforce</label>
                    <input type="radio" id="selligent" value="Selligent" v-model="selectedPlatform" />
                    <label for="selligent">Selligent</label>
                </div>
                <div class="tagging-tool__form-field tagging-tool__form-field--text">
                    <p>Tagging Code</p>

                    <input v-if="this.selectedPlatform=='Selligent'" type="text" v-model="taggingCode" placeholder="Tagging Code" />
                    <input v-else type="text" v-model="taggingCode" placeholder="Not necessary on Salesforce" disabled="disabled"/>
                </div>
                <div class="tagging-tool__form-field tagging-tool__form-field--text">
                    <p>Image Pixel Source</p>
                    
                    <input type="text" v-model="pixelSource" placeholder="Currently Not In Use" disabled="disabled" />
                </div>
                <div class="tagging-tool__btn-container">
                    <button
                        v-on:click.prevent="submitTagging"
                        class="tagging-tool__btn">
                        Submit
                    </button>
                </div>
            </form>
        </Modal>
    </div>
</template>

<script>
import { bus } from "../main";
import Modal from "./Modal.vue";

export default {
    name: "TaggingTool",
    components: {
        Modal,
    },
    data() {
        return {
            selectedPlatform: "Salesforce",
            taggingCode: "",
            pixelSource: "",
        };
    },
    mounted() {
        bus.$on("openTaggingTool", () => {
            this.$refs.modal.show();
        });
    },
    methods: {
        submitTagging() {
            bus.$emit("taggingToolSubmitted", this.taggingCode);
            bus.$emit("platformSubmitted", this.selectedPlatform);
            this.$refs.modal.hide();
        },
    },
};
</script>

<style scoped>

.tagging-tool__label {
    height: var(--modal-top-bar-height);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    font-weight: bold;
    font-size: 20px;
    color: hsl(46, 50%, 60%);
}

.tagging-tool__form {
    width: 100%;
    padding-right: 9px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}

.tagging-tool__form-field {
    flex-basis: 100%;
    flex-grow: 1;
}

.tagging-tool__form-field p {
    min-height: 24px;
    display: block;
    margin-bottom: 2px;
    font-weight: bold;
}
.tagging-tool__form-field input {
    padding: 8px 12px;
    background-color: hsl(220, 13%, 91%);
    color: hsl(217, 19%, 27%);
    border: 2px solid white;
    border-radius: 8px;
    line-height: 1.2;
}
.tagging-tool__form-field--text input {
    width: 100%;
}
.tagging-tool__form-field--radio input {
    margin-right: 0.5rem;
}
.tagging-tool__form-field--radio label {
    margin-right: 1rem;
}
.tagging-tool__form-field label {
    min-height: 24px;
    margin-bottom: 2px;
}

.tagging-tool__form input:focus {
    border: 2px solid hsl(46, 50%, 60%);
    outline: 2px solid white;
    outline-offset: 2px;
}
.tagging-tool__form input:disabled {
    background-color: hsl(220, 13%, 85%);
    opacity: 0.4;
    cursor: not-allowed;
}


.tagging-tool__btn-container {
    width: 100%;
    height: var(--modal-bottom-bar-height);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.tagging-tool__btn {
    margin: 0 16px 0 0;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: hsl(162, 72%, 37%);
    color: #fff;
}

.tagging-tool__btn:hover {
    background-color: hsl(162, 72%, 43%);
}
</style>
