import template from './template.handlebars';
import createModuleObject from '../createModuleObject';

const copyModule1 = createModuleObject('Copy Module 1', template, {
    mainHeading: {
        label: 'H1',
        value: 'Placeholder h1 heading',
    },
    subHeading1: {
        label: 'H2',
        value: 'Placeholder h2 subheading'
    },
    subHeading2: {
        label: 'H3',
        value: 'Placeholder h3 subheading'
    },
    subHeading3: {
        label: 'H4',
        value: 'Placeholder h4 subheading'
    },
    body: {
        label: 'Body Copy',
        value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque mollis nibh arcu. Sed blandit sodales ante, vitae posuere risus. Vivamus et varius arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Praesent vulputate rhoncus turpis, et condimentum dui sollicitudin vitae.'
    },
    copyPaddingDT: {
        label: 'DT text padding',
        value: '16px 100px 0 100px'
    },
    spacerBottom: {
        label: 'Under CTA space',
        value: '16'
    },
    ctaText: {
        label: 'CTA Button Text',
        value: 'I am a button!'
    },
    ctaLink: {
        label: 'CTA Button Link',
        value: 'https://direct.asda.com/george/clothing/10,default,sc.html'
    },    
    ctaBgColor: {
        label: 'CTA Background Colour',
        value: '#000000'
    },
    ctaTextColor: {
        label: 'CTA Text',
        value: '#ffffff'
    },
    ctaBorder: {
        label: 'CTA Border',
        value: '1px solid #000000'
    }
    
});

export default copyModule1;