<template>
	<div>
		<div v-show="showing" aria-labelledby="modal-title" role="dialog" aria-modal="true">
			<div class="email-modal__overlay email-modal__background backdrop-filter backdrop-blur-sm backdrop-brightness-50" v-on:click="hide()"></div>
			<div class="email-modal__container">
				<div class="email-modal__heading">{{ heading }}</div>
				<div class="email-modal__content">
					<slot></slot>
				</div>
				<button class="email-modal__close" v-on:click="hide()">Close X</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Modal",
	data() {
		return {
			showing: false,
		};
	},
	props: {
		heading: String,
	},
	methods: {
		show() {
			this.showing = true;
		},
		hide() {
			this.showing = false;
			this.closeActiveEmailMenu();
		},
	},
};
</script>
<style scoped>
.email-modal__background {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.email-modal__container {
	--modal-top-bar-height: 60px;
	--modal-bottom-bar-height: 60px;
	width: 100%;
	max-width: 1024px;
	max-height: calc(100vh - 64px);
	margin: 0 auto;
	padding: var(--modal-top-bar-height) 16px var(--modal-bottom-bar-height);
	display: flex;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
}

.email-modal__heading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: var(--modal-top-bar-height);
	position: absolute;
	top: 0;
	left: 16px;
	z-index: 10;
}

.email-modal__content {
	max-height: 100%;
	width: 100%;
	overflow-y: scroll;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
}

.email-modal__close {
	display: flex;
	justify-content: center;
	align-items: center;
	height: var(--modal-top-bar-height);
	position: absolute;
	top: 0;
	right: 16px;
	z-index: 10;
}
.email-modal__close:hover {
	transform: scale(1.05);
}
</style>
