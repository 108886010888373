<template>
  <div>
    <Modal ref="modal" heading="Select Module:">
      <div class="module-selector__btn-container">
        <template v-for="module in modules" >
          <button
            v-bind:key="module.id"
            v-if="!/^(?:content|copy)/i.test(module.label)"
            v-on:click="selectModule(module)"
            class="module-selector__btn">
            {{ module.label }}
          </button>
        </template>
      </div>
      <div class="module-selector__btn-container">
        <template v-for="module in modules" >
          <button
            v-bind:key="module.id"
            v-if="/^content/i.test(module.label)"
            v-on:click="selectModule(module)"
            class="module-selector__btn">
            {{ module.label }}
          </button>
        </template>
      </div>
      <div class="module-selector__btn-container">
        <template v-for="module in modules" >
          <button
            v-bind:key="module.id"
            v-if="/^copy/i.test(module.label)"
            v-on:click="selectModule(module)"
            class="module-selector__btn">
            {{ module.label }}
          </button>
        </template>
      </div>
    </Modal>
  </div>
</template>

<script>
import { bus } from "../main";
import modulesArray from "../modules";
import Modal from "./Modal.vue";

export default {
  name: "ModuleSelector",
  components: {
    Modal,
  },
  data() {
    return {
      modules: modulesArray,
      showing: false,
    };
  },
  mounted() {
    bus.$on("openModuleSelector", () => {
      this.$refs.modal.show();
    });
  },
  methods: {
    selectModule(module) {
      bus.$emit("moduleSelected", module);
      this.$refs.modal.hide();
    },
  },
};
</script>

<style scoped>
.module-selector__btn-container {
  --hue-original: 220;
  --hue: var(--hue-original);
  width: 100%;
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.module-selector__btn {
  padding: 8px 12px;
  flex: 0 0 calc(25% - 16px);
  border-radius: 8px;
  background-color: hsl(var(--hue), 24%, 36%);
  color: #fff;
}
.module-selector__btn:hover {
  background-color: hsl(var(--hue), 26%, 42%);
}
.module-selector__btn-container:nth-of-type(2) {
  --hue: calc(var(--hue-original) + (1 * 100));
}
.module-selector__btn-container:nth-of-type(3) {
  --hue: calc(var(--hue-original) + (2 * 100));
}
.module-selector__btn-container:nth-of-type(4) {
  --hue: calc(var(--hue-original) + (3 * 100));
}
.module-selector__btn-container:nth-of-type(5) {
  --hue: calc(var(--hue-original) + (4 * 100));
}
</style>