<template>
	<div class="email-preview">
		<div class="email-preview__btn-container">
			<button class="email-preview__btn email-preview__btn--desktop tooltip" v-on:click="iframeWidth = 'iw-desktop'">
				<img src="icon-preview-desktop.svg" alt="preview desktop icon" />
				<span class="tooltip-text">Desktop</span>
			</button>
			<button class="email-preview__btn email-preview__btn--mobile tooltip" v-on:click="iframeWidth = 'iw-mobile'">
				<img src="icon-preview-mobile.svg" alt="preview mobile icon" />
				<span class="tooltip-text">Mobile</span>
			</button>
			<button class="email-preview__btn email-preview__btn--html tooltip" v-on:click="openHtmlCode">
				<img src="icon-preview-html.svg" alt="preview html icon" />
				<span class="tooltip-text">HTML</span>
			</button>
			<button class="email-preview__btn email-preview__btn--save btn" v-on:click="saveEmail">Save</button>
			<button class="email-preview__btn email-preview__btn--save-as btn" v-on:click="openSaveModal">Save as</button>
			<button class="email-preview__btn email-preview__btn--search btn" v-on:click="openLoadModal">Search</button>
		</div>
		<div class="email-preview__iframe-container">
			<iframe v-bind:class="['email-preview__iframe', iframeWidth]" ref="previewHtml" />
		</div>
	</div>
</template>

<script>
import { bus } from "../main";
import startingContent from "../startingContent.handlebars";

export default {
	name: "Preview",
	data() {
		return {
			htmlCode: startingContent,
			iframeWidth: "iw-desktop",
		};
	},
	computed: {
		html() {
			return this.htmlCode;
		},
	},
	mounted() {
		// eslint-disable-next-line
		this.updateIframe();
		bus.$on("modulesUpdated", (payload) => {
			this.htmlCode = payload;
			this.updateIframe();
		});
		bus.$on("taggingToolSubmitted", () => {
			this.updateIframe();
		});
	},
	methods: {
		updateIframe() {
			const iframeDoc = this.$refs.previewHtml.contentWindow.document;
			iframeDoc.open();
			iframeDoc.write(this.html);
			iframeDoc.close();
		},
		openHtmlCode() {
			bus.$emit("openHtml", this.html);
		},
		openSaveModal() {
			bus.$emit("openSaveModal");
		},
		saveEmail() {
			bus.$emit("saveEmail");
		},
		openLoadModal() {
			bus.$emit("openLoadModal");
		},
	},
};
</script>

<style scoped>
iframe.iw-desktop {
	width: 100%;
}
iframe.iw-mobile {
	width: 390px;
}

.email-preview {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 100vh;
	background-color: hsl(217, 19%, 20%);
}

.email-preview__btn-container {
	margin: 16px 16px;
	display: flex;
}

.email-preview__btn {
	margin: 0 8px;
	padding: 8px 8px;
}

.email-preview__btn.btn {
	margin: 0 8px;
	padding: 8px 16px;
	background-color: hsl(217, 19%, 42%);
	box-shadow: 0 2px 4px rgb(0 0 0 / 50%), inset 0 2px 4px rgb(255 255 255 / 10%);
	border-radius: 8px;
	color: white;
}

.email-preview__btn--mobile {
	margin-right: auto;
}

.email-preview__btn--html {
	margin-left: auto;
}

.email-preview__btn img {
	margin: 0 auto;
	width: 24px;
	height: 24px;
}

.email-preview__btn:hover img {
	transform: scale(1.1) rotate(1deg);
}

.tooltip {
	position: relative;
}

.tooltip .tooltip-text {
	visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: auto;
	bottom: 0;
	left: 50%;
	right: auto;
	transform: translate(-50%, 100%);
	margin: 0;
	padding: 4px 8px;
	border-radius: 4px;
	background-image: radial-gradient(circle, #374151, #333);
	opacity: 0.95;
	color: #fff;
	font-size: 14px;
}

.tooltip:hover .tooltip-text {
	visibility: visible;
}

.email-preview__iframe-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	flex-grow: 1;
	background-color: #e3e3e9;
}

.email-preview__iframe {
	background: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
</style>
