<template>
	<li class="flex justify-between p-3 email-item">
		<div class="w-1/6">
			<span>{{ email.createdAt }}</span>
		</div>
		<div class="w-1/6">
			<span>{{ email.updatedAt }}</span>
		</div>
		<div class="w-3/6">
			<p>{{ email.label }}</p>
		</div>
		<div class="relative w-1/6">
			<button v-on:click="toggleMenu" ref="toggle">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
					<path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
				</svg>
			</button>
			<div ref="menu" class="hidden absolute left-0 bottom-0 bg-white shadow-md rounded-md load-item-menu p-2">
				<ul>
					<li>
						<button class="p-2" v-on:click="loadEmail">Load</button>
					</li>
					<li>
						<button class="p-2" v-on:click="deleteEmail">Delete</button>
					</li>
				</ul>
			</div>
		</div>
	</li>
</template>

<script>
import { bus } from "../main";

export default {
	name: "LoadModalItem",
	props: {
		email: {
			type: Object,
		},
	},
	mounted() {
		document.addEventListener("click", this.closeMenu);
	},
	methods: {
		closeMenu(event) {
			if (this.$refs.toggle !== event.target && this.$refs.menu !== event.target && !this.$refs.toggle.contains(event.target) && !this.$refs.menu.contains(event.target) && !this.$refs.menu.classList.contains("hidden")) {
				this.$refs.menu.classList.add("hidden");
			}
		},
		toggleMenu() {
			this.$refs.menu.classList.toggle("hidden");
		},
		deleteEmail() {
			if (!window.confirm(`Are you sure you want to delete ${this.email.label}`)) {
				return;
			}
			fetch(`${process.env.VUE_APP_API_ENDPOINT}/delete`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					id: this.email.id,
				}),
			}).then(async (res) => {
				console.log(await res.json());
				this.$emit("emailDeleted");
			});
		},
		loadEmail() {
			if (!window.confirm(`You are about to overwrite your current content with the last saved state of ${this.email.label}. Are you sure you want to do this?`)) {
				return;
			}
			bus.$emit("loadEmail", this.email);
		},
	},
};
</script>

<style scoped>
.load-item-menu {
	transform: translate(-101%, 70%);
}

.email-item:hover {
	background-color: #e0e0e0;
}

.email-item > div {
	margin-right: 20px;
}

.email-item > :last-child {
	margin-right: 0;
}
</style>
