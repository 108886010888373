<template>
	<div>
		<Modal ref="modal">
			<div class="load-scroll">
				<ul>
					<li class="flex justify-between p-3">
						<div class="w-1/6">
							<p><strong>Create at</strong></p>
						</div>
						<div class="w-1/6">
							<p><strong>Last updated</strong></p>
						</div>
						<div class="w-3/6">
							<p><strong>Name</strong></p>
						</div>
						<div class="w-1/6">
							<p><strong>Options</strong></p>
						</div>
					</li>
					<LoadModalItem v-for="email in savedEmails" v-bind:key="email.id" v-bind:email="email" v-on:emailDeleted="emailDeleted(email.id)" />
				</ul>
			</div>
			<form class="flex absolute left-0 top-0 p-4">
				<input type="text" placeholder="Search emails" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" v-model="searchString" />
			</form>
		</Modal>
	</div>
</template>

<script>
import { bus } from "../main";
import Modal from "./Modal.vue";
import LoadModalItem from "./LoadModalItem.vue";

export default {
	name: "LoadModal",
	components: {
		Modal,
		LoadModalItem,
	},
	data() {
		return {
			showing: false,
			savedEmails: [],
			searchString: "",
		};
	},
	mounted() {
		bus.$on("openLoadModal", () => {
			this.$refs.modal.show();
			// if search has previously been done reset search to empty string, which will run getAllEmails
			if (this.searchString) {
				this.searchString = "";
				return;
			}
			this.getAllEmails();
		});
		bus.$on("loadEmail", ({ label }) => {
			this.$refs.modal.hide();
			this.$alert(`${label} loaded`);
		});
	},
	methods: {
		getDate(isoString) {
			const date = new Date(isoString);

			return date.toDateString();
		},
		emailDeleted(id) {
			const deletedEmailIndex = this.savedEmails.findIndex((email) => email.id === id);
			this.savedEmails.splice(deletedEmailIndex, 1);
		},
		getAllEmails() {
			fetch(`${process.env.VUE_APP_API_ENDPOINT}/emails?orderBy=createdAt`)
				.then(async (res) => {
					const response = await res.json();
					this.savedEmails = response.map(({ id, createdAt, updatedAt, label, modules, pageHandlebar }) => ({ id, createdAt: this.getDate(createdAt), updatedAt: this.getDate(updatedAt), label, modules, pageHandlebar }));
				})
				.catch((e) => console.log(e));
		},
	},
	watch: {
		searchString: function (val) {
			if (val === "") {
				this.getAllEmails();
				return;
			}
			fetch(`${process.env.VUE_APP_API_ENDPOINT}/search?label=${val}`).then(async (res) => {
				const response = await res.json();
				this.savedEmails = response.map(({ id, createdAt, updatedAt, label, modules, pageHandlebar }) => ({ id, createdAt: this.getDate(createdAt), updatedAt: this.getDate(updatedAt), label, modules, pageHandlebar }));
			});
		},
	},
};
</script>

<style scoped>
.load-scroll {
	height: calc(24rem - 3rem - 1rem);
	overflow-y: scroll;
	width: 100%;
}
</style>
