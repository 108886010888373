<template>
	<div>
		<Modal ref="modal">
			<div>
				<form v-on:submit.prevent="saveAs">
					<div class="p-2">
						<label class="block font-bold mb-1 md:mb-0 pr-4">Save as</label>
						<input type="text" v-model.trim="saveAsLabel" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" />
					</div>
					<div class="w-full flex justify-end p-2">
						<button type="submit" v-bind:disabled="disableSaveButton" class="bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Save</button>
					</div>
				</form>
			</div>
		</Modal>
	</div>
</template>

<script>
import { bus } from "../main";
import Modal from "./Modal.vue";

export default {
	name: "SaveModal",
	components: {
		Modal,
	},
	data() {
		return {
			saveAsLabel: "",
			showing: false,
			emailsWithLabelExists: false,
			disableSaveButton: false,
		};
	},
	methods: {
		async saveAs() {
			if (!this.saveAsLabel) {
				this.$alert("Give the email a label");
				return;
			}

			this.disableSaveButton = true;

			const existingEmailId = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/checklabel?label=${this.saveAsLabel}`)
				.then(async (res) => {
					const response = await res.json();
					if (response.length > 0) {
						return response[0].id;
					}
					return null;
				})
				.catch((e) => console.log(e));

			if (existingEmailId && !window.confirm(`You are about to overwrite the saved content for ${this.saveAsLabel}. Are you sure you want to do this?`)) {
				return;
			}

			bus.$emit("saveAs", {
				label: this.saveAsLabel,
				existingEmailId,
			});

			this.disableSaveButton = false;
			this.$refs.modal.hide();
		},
	},
	mounted() {
		bus.$on("openSaveModal", () => {
			this.$refs.modal.show();
		});
		bus.$on("saveEmail", () => {
			this.saveAs();
		});
		bus.$on("loadEmail", ({ label }) => {
			this.saveAsLabel = label;
		});
	},
};
</script>
