import template from './template.handlebars';
import createModuleObject from '../createModuleObject';
import imageOptionsGenerator from '../../utils/imageOptionsGenerator';
import htmlOptionsGenerator from '../../utils/htmlOptionsGenerator';

const imageOptions = imageOptionsGenerator(2, 2, 2)
const htmlOption = htmlOptionsGenerator(2)
const contentModule4 = createModuleObject('Content Module 4', template, Object.assign({}, imageOptions, htmlOption, {

    paddingColrf: {
        label: 'Padding Between Columns',
        value: '',
    }
}));

export default contentModule4;