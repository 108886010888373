const htmlOptionsGenerator = (numberOfText) => {
  return [...Array(numberOfText).keys()].reduce((accumulator, current, index) => {
    const currentIndexPlus1 = index + 1;

    accumulator[`html${currentIndexPlus1}`] = {
      label: `HTML ${currentIndexPlus1}`,
      value: "<p style=\"font-family:'Lato', Arial, Helvetica, sans-serif; letter-spacing: 0.25px; font-size:14px; line-height: 18px; text-decoration:none !important;text-align:center;Margin:0;\"> Ivory Tie Neck Cape Blouse</p> <p style=\"font-family:'Lato', Arial, Helvetica, sans-serif; letter-spacing: 0.25px; font-size:18px; line-height: 34px;font-weight: 700;text-decoration:none !important;text-align:center;Margin-top:7px;\"> &pound;<span style=\"font-family:'Lato', Arial, Helvetica, sans-serif; letter-spacing: 0.25px; font-size:32px; line-height: 34px;\">14</span> </p>",
    };

    return accumulator;
  }, {});
};

export default htmlOptionsGenerator;
