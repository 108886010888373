/**
 * Creates a module object 
 * @param {String} label 
 * @param {String} template 
 * @param {object} editableAttributes 
 * @returns Module object
 */
const createModuleObject = (label, template, editableAttributes = {}, includeSectionEditableAttributes = true) => {
    
    const sectionEditableAttributes = {
        sectionTopPadding: {
            label: 'Section Top Padding',
            value: '0',
            formGroup: 'sectionSettings',
        },
        sectionRightPadding: {
            label: 'Section Right Padding',
            value: '0',
            formGroup: 'sectionSettings',
        },
        sectionBottomPadding: {
            label: 'Section Bottom Padding',
            value: '16',
            formGroup: 'sectionSettings',
        },
        sectionLeftPadding: {
            label: 'Section Left Padding',
            value: '0',
            formGroup: 'sectionSettings',
        },
        bgColour:{
            label:"Background colour",
            value:"",
            formGroup:'sectionSettings',
        },
        textColor:{
            label: 'Text Colour',
            value: '#000000'
        },
    };

    const editableAttributesObject = includeSectionEditableAttributes ? Object.assign( editableAttributes, sectionEditableAttributes) : editableAttributes;
    return {
        id: label.toLowerCase().replace(/\s/g, ''),
        label,
        template,
        editableAttributes: editableAttributesObject
    }
}

export default createModuleObject;