import Handlebars from 'handlebars';

import ImagePartial from './partials/image.handlebars';
import responsiveImage from './partials/responsiveImage.handlebars'

const handlebars = Handlebars.create();

// Helpers
handlebars.registerHelper('and', (arg1, arg2) => arg1 && arg2 );
handlebars.registerHelper('concat', (...args) => args.join());
handlebars.registerHelper('plusOne', (arg) => arg.parseInt());
handlebars.registerHelper('getProp', (objectArg, propArg) => objectArg[propArg]);
handlebars.registerHelper('any', (...args) => args.some(arg => arg));
handlebars.registerHelper('ifEquals', (arg1, arg2, ) => arg1 == arg2);

// Partials
handlebars.registerPartial('image',ImagePartial)
handlebars.registerPartial('responsiveImage',responsiveImage)

export default handlebars;