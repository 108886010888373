import fashionHeader from './FashionHeader'
// import fashionFooter from './FashionFooter'
// import homeHeader from './HomeHeader'
// import homeFooter from './HomeFooter'
// import serviceStrip from './ServiceStrip'

import contentModule1 from './ContentModule1'
// import contentModule2A from './ContentModule2A'
// import contentModule2B from './ContentModule2B'
// import contentModule3A from './ContentModule3A'
// import contentModule3B from './ContentModule3B'
import contentModule4 from './ContentModule4'
// import contentModule5A from './ContentModule5A'
// import contentModule5B from './ContentModule5B'
// import contentModule6 from './ContentModule6'
// import contentModule7A from './ContentModule7A'
// import contentModule7B from './ContentModule7B'
// import contentModule8 from './ContentModule8'


import copyModule1 from './CopyModule1'
// import copyModule2 from './CopyModule2'
// import copyModule3 from './CopyModule3'
// import copyModule4 from './CopyModule4'
// import copyModule5 from './CopyModule5'



export default [
    fashionHeader,
    // fashionFooter,
    // homeHeader,
    // homeFooter,
    // serviceStrip,
  
    contentModule1,
    // contentModule2A,
    // contentModule2B,
    // contentModule3A,
    // contentModule3B,
    contentModule4,
    // contentModule5A,
    // contentModule5B,
    // contentModule6,
    // contentModule7A,
    // contentModule7B,
    // contentModule8,
      
    copyModule1,
    // copyModule2,
    // copyModule3,    
    // copyModule4,
    // copyModule5
]