import template from './template.handlebars';
import createModuleObject from '../createModuleObject';

const fashionModule = createModuleObject('Fashion Header', template, {
    signInCta: {
        label: 'Sign In CTA Html',
        value: '<a href="https://migeorge.asda.com/p/cp/91786246150c9a1d/c?mi_u=[%[ID]%]&url=https%3A%2F%2Fmigeorge.asda.com%2Fp%2Frp%2F9a996d8c69b227cf%2Furl" style="display: block"><img alt="Items in basket" align="right" src="https://migeorge.asda.com/p/rp/9a996d8c69b227cf.png?mi_u=[%[ID]%]" /></a>'
    }
},false);

export default fashionModule;