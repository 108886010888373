<template>
	<div class="email-editor">
		<div class="email-editor__btn-container">
			<button class="email-editor__btn" v-on:click="addModule">+ Add Module</button>
			<button class="email-editor__btn" v-on:click="taggingTool">Tagging Tool</button>
		</div>
		<div class="email-editor__tier-container">
			<div class="email-editor__tier" v-for="(module, index) in modules" v-bind:key="module.id">
				<div class="email-module">
					<div class="email-module__label">
						<span class="email-module__label__tier-index">{{ index + 1 }}</span
						>{{ module.label }}
					</div>
					<div class="email-module__btn-container">
						<slot>
							<button v-if="Object.keys(module.editableAttributes) && Object.keys(module.editableAttributes).length > 1" class="email-module__btn email-module__btn--edit" v-on:click="editModule(index)">
								<img src="icon-module-edit.svg" alt="edit icon" />
								Edit
							</button>
							<div v-else class="email-module__btn email-module__btn--placeholder"></div>
						</slot>
						<button class="email-module__btn email-module__btn--duplicate" v-on:click="duplicateModule(index)">
							<img src="icon-module-duplicate.svg" alt="duplicate icon" />
							Duplicate
						</button>
						<button class="email-module__btn email-module__btn--up" v-if="index > 0" v-on:click="moveModuleUp(index)">
							<img src="icon-module-move-up.svg" alt="move-up icon" />
							Move Up
						</button>
						<button class="email-module__btn email-module__btn--down" v-if="index + 1 < modules.length" v-on:click="moveModuleDown(index)">
							<img src="icon-module-move-down.svg" alt="move-down icon" />
							Move Down
						</button>
						<button class="email-module__btn email-module__btn--delete" v-on:click="deleteModule(index)">
							<img src="icon-module-delete.svg" alt="delete icon" />
							Delete
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { bus } from "../main";
import startingContent from "../startingContent.handlebars";
import handlebarsRender from "../modules/handlebarsRender";

const splitStartingContent = startingContent.split("<!-- Email Body : START -->");

export default {
	name: "Editor",
	data() {
		return {
			modules: [],
			taggingCode: "",
			selectedPlatform: "",
		};
	},
	computed: {
		renderedHtml() {
			const moduleStrings = this.modules.map((module) => handlebarsRender(module.template, module.editableAttributes, module.moduleName));
			const htmlCodeString = [splitStartingContent[0], ...moduleStrings, splitStartingContent[1]].join("");

			// guard to return html without any tagging applied if tagging code not submitted
			if (!this.taggingCode) {
				return htmlCodeString;
			}
			if (this.selectedPlatform === "Salesforce") {
				return htmlCodeString;
			}

			// regex to capture any href inside <a> tags
			const regexHrefInAnchorTags = /(?<=<a[^>]*)href=["']([^"']+)["']/gi;

			const tagging = this.taggingCode;
			const selligentMasterTagging = "mi_u=[%[MASTER.ID]%]";

			// function to add tagging to captured hrefs
			const tagger = (match, p1) => {
				// return original hrefs without tagging
				if (p1.includes("unsubscribe/Unsubscribe.html") || p1.includes("<%domain.webDomain%>/public/read_message.jsp")) {
					return match;
				}

				// construct new href with tagging based on a check if url contains a '?' character
				const hrefValue = p1.includes("?") ? `${p1}&${tagging}&${selligentMasterTagging}` : `${p1}?${tagging}&${selligentMasterTagging}`;
				return `href="${hrefValue}"`;
			};

			const htmlWithTagging = htmlCodeString.replace(regexHrefInAnchorTags, tagger);

			return htmlWithTagging;
		},
	},
	watch: {
		renderedHtml: {
			handler(value) {
				bus.$emit("modulesUpdated", value);
			},
		},
	},
	methods: {
		addModule() {
			bus.$emit("openModuleSelector");
		},
		taggingTool() {
			bus.$emit("openTaggingTool");
		},
		editModule(moduleIndex) {
			const deepClone = JSON.parse(JSON.stringify(this.modules[moduleIndex]));
			bus.$emit("editingModule", { ...deepClone, tierIndex: moduleIndex });
		},
		deleteModule(moduleIndex) {
			this.modules.splice(moduleIndex, 1);
		},
		moveModuleUp(currentIndex) {
			this.moveModule(currentIndex, currentIndex - 1);
		},
		moveModuleDown(currentIndex) {
			this.moveModule(currentIndex, currentIndex + 1);
		},
		moveModule(currentIndex, newIndex) {
			const modulesArrCopy = Object.assign([], this.modules);
			const moduleObjectCopy = Object.assign({}, this.modules[currentIndex]);
			// remove item
			modulesArrCopy.splice(currentIndex, 1);
			// inset item
			modulesArrCopy.splice(newIndex, 0, moduleObjectCopy);

			this.modules = Object.assign([], modulesArrCopy);
		},
		duplicateModule(index) {
			// make copies of module and modules (tiers) array
			const moduleCopy = { ...this.modules[index] };
			const moduleArrayCopy = [...this.modules];
			// generate new unique id & replacing existing
			moduleCopy.id = moduleCopy.id.replace(/[0-9]*_/g, `${Date.now()}_`);
			// insert copied module after selected module
			moduleArrayCopy.splice(index, 0, moduleCopy);

			// assign modules (tiers) array with spliced copy
			this.modules = [...moduleArrayCopy];
		},
	},
	mounted() {
		bus.$on("moduleSelected", (payload) => {
			const { label, id, template, editableAttributes } = payload;
			this.modules.push({
				id: `${Date.now()}_${id}`,
				moduleName: `${id}`,
				label,
				template,
				editableAttributes: editableAttributes ? editableAttributes : [],
			});
		});
		bus.$on("taggingToolSubmitted", (payload) => {
			this.taggingCode = payload;
		});
		bus.$on("platformSubmitted", (payload) => {
			this.selectedPlatform = payload;
		});
		bus.$on("updateModule", (payload) => {
			const moduleIndex = this.modules.findIndex((module) => module.id === payload.id);
			this.modules[moduleIndex] = Object.assign(this.modules[moduleIndex], payload);
		});
		bus.$on("saveAs", (payload) => {
			const { label, existingEmailId } = payload;
			fetch(`${process.env.VUE_APP_API_ENDPOINT}/${existingEmailId ? "update" : "save"}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					label: label,
					modules: this.modules,
					existingEmailId,
				}),
			}).then(async (res) => {
				console.log(await res.json());
				this.$alert(`${label} saved`);
			});
		});
		bus.$on("updateEmail", (payload) => {
			fetch(`${process.env.VUE_APP_API_ENDPOINT}/update`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					label: payload.label,
					modules: this.modules,
				}),
			}).then(async (res) => console.log(await res.json()));
		});
		bus.$on("loadEmail", (payload) => {
			this.modules = payload.modules;

			fetch(`${process.env.VUE_APP_API_ENDPOINT}/updatePageHandlebar`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					pageHandlebar: payload.pageHandlebar,
				}),
			});
		});
	},
};
</script>
<style scoped>
.email-editor {
	display: flex;
	flex-direction: column;
	background-color: hsl(217, 19%, 27%);
	color: white;
}

.email-editor__btn-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 16px 16px;
}

.email-editor__btn {
	margin: 0 8px;
	padding: 8px 16px;
	background-color: hsl(217, 19%, 42%);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(255, 255, 255, 0.1);
	border-radius: 8px;
}
.email-editor__btn:hover {
	background-color: hsl(217, 19%, 48%);
}

.email-editor__btn:nth-of-type(1) {
	margin-left: 0;
}
.email-editor__btn:nth-last-of-type(1) {
	margin-right: 0;
}

.email-editor__tier-container {
	overflow-y: scroll;
	margin: 10px 7px 0px 16px;
	padding-right: 9px;
}

.email-editor__tier {
}

.email-module {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	background-color: hsl(217, 19%, 32%);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(255, 255, 255, 0.1);
	border-radius: 16px;
	overflow: hidden;
}
.email-module__label {
	width: 100%;
	padding: 4px 8px;
	display: flex;
	align-items: center;
	background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.1) 30%, transparent);
	color: hsl(46, 100%, 90%);
	font-size: 16px;
	pointer-events: none;
	filter: drop-shadow(0px 2px 4px hsla(0, 0%, 0%, 0.25));
}

.email-module__label__tier-index {
	width: 22px;
	height: 22px;
	margin-right: 8px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: hsl(46, 100%, 90%);
	color: hsl(217, 19%, 32%);
	font-size: 18px;
}

.email-module__btn-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.email-module__btn {
	flex: 0 1 20%;
	margin: 0;
	padding: 4px 8px;
	font-size: 13px;
}
.email-module__btn:hover {
	background-color: rgba(0, 0, 0, 0.2);
}
.email-module__btn--duplicate {
	margin-right: auto;
}
.email-module__btn--delete {
	color: #dc3545;
	margin-left: auto;
}
.email-module__btn--placeholder:hover {
	background-color: transparent;
}
.email-module__btn img {
	margin: 0 auto;
	width: 20px;
	height: 20px;
}
</style>
