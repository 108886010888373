import template from './template.handlebars';
import createModuleObject from '../createModuleObject';

const contentModule1 = createModuleObject('Content Module 1', template, {
    imgSrcDesktop: {
        label: 'Image Desktop Src',
        value: 'https://via.placeholder.com/700x400',
    },
    imgSrcMobile: {
        label: 'Image Mobile Src',
        value: 'https://via.placeholder.com/596x300',
    },
    altTag: {
        label: 'Alt Tag',
        value: 'Alt Tag',
    },
    href: {
        label: 'Link',
        value: 'https://direct.asda.com/george/clothing/10,default,sc.html',
    },
    flexText: {
        label: 'HTML DT',
        value: '<p class="dark-pp" style="font-weight: 700;">Black Egg Chair<br/><i style="text-decoration:line-through;">Was 249.00</i><br/><span style="font-size:22px; line-height: 34px;font-weight: 700;">Now<br/><span style="font-size:32px; line-height: 34px;">199.00</span></p>',
    },
    flexTextM: {
        label: 'HTML MB',
        value: '<p class="dark-pp" style="font-weight: 700;">Black Egg Chair<br/><i style="text-decoration:line-through;">Was 249.00</i><br/><span style="font-size:22px; line-height: 34px;font-weight: 700;">Now<br/><span style="font-size:32px; line-height: 34px;">199.00</span></p>',
    }
});

export default contentModule1;