import './assets/alertWrapper.css'

const alertDialogEl = document.createElement('div');
alertDialogEl.classList.add('p-3', 'mt-3', 'shadow-xl', 'bg-blue-100', 'rounded', 'z-10', 'transition-all', 'ease-in-out');

const removeAlert = (el) => {
    el.classList.add('opacity-0');
    setTimeout( () => {
        el.remove()
    }, 1000 )
}

export default {
    install: (Vue) => {
        Vue.prototype.$alert = function(message) {
            const rootEl = document.querySelector('.js-alert-wrapper');
            const newAlertDialogEl = alertDialogEl.cloneNode();
            newAlertDialogEl.innerText = message;
            rootEl.insertAdjacentElement('beforeend', newAlertDialogEl);
            setTimeout( () => {
                removeAlert( newAlertDialogEl );
            }, 3000 );
        }
    }
}