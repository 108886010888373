import Vue from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import vueAlertPlugin from "./vueAlertPlugin";

Vue.config.productionTip = false;

Vue.use(vueAlertPlugin);

export const bus = new Vue();

new Vue({
    render: (h) => h(App),
}).$mount("#app");
